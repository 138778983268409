@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }

  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

.team-container {
  text-align: center;
}

.container-team {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-title-team {
  max-width: 600px;
  /* Ajuste conforme necessário */
}

.team-photos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 60px;
  /* Espaçamento entre as fotos */
}

.team-member {
  flex: 1 1 200px;
  /* Ajusta a base e a flexibilidade do item */
}

.thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-img {
  width: 100%;
  /* Ou uma largura fixa, conforme necessário */
  max-width: 150px;
  /* Ajuste conforme o tamanho desejado */
}